<template>
  <div class="before-class-card left">
    <div class="before-class-card__title" :style="{color}">
      <div><img :src="icon" alt="">{{ title }}</div>
    </div>

    <div :style="{color}" class="before-class-card__content hidden-scroll" :class="{'video': title=== '动画预告'}">
      <div v-if="title !== '动画预告'">
<!--        <el-carousel>-->
<!--          <el-carousel-item v-for="(item, key) in zuohuaList" :key="key" :autoplay="false">-->
<!--            <viewer style="height: 100%">-->
<!--              <img :src="item" alt="" style="width: 100%">-->
<!--            </viewer>-->
<!--          </el-carousel-item>-->
<!--        </el-carousel>-->
        <img-swipe :urls="data[t]" />
      </div>
      <div v-else style="position: relative; height: 100%; line-height: 100%" @click="hFirstPlay('动画预告')">
        <video :src="data | yugaoFilter" style="max-width: 100%; max-height: 100%; vertical-align: bottom;display: inline-block" :controls="play" ref="video"
               poster="../assets/image/bg_videodh.png" :class="{'first': first}"></video>
        <img src="../assets/image/ic_play.png" alt="" class="play" v-if="first">
      </div>
    </div>
  </div>
</template>

<script>
import ImgPreview from "@/components/ImgPreview";
import ImgSwipe from "@/components/imgSwipe";
export default {
  name: "BeforeClassCard",
  components: {ImgSwipe, ImgPreview},
  props: {
    color: String,
    icon: String,
    title: String,
    t: String,
    data: Object,
  },
  filters: {
    yugaoFilter(a) {
      if (a.resourcesList && a.resourcesList.length>0) {
        let list = a.resourcesList.filter(v=>v.typeName === '动画预告')
        console.log(list)
        if (list.length > 0){
          return list[0].urls[0]
        }
      }
    }
  },
  computed:{
    // zuohuaList(){
    //   console.log(this.data)
    //   if (this.data.resourcesList && this.data.resourcesList.length>0){
    //     let list = this.data.resourcesList.filter(v=>v.typeName === '作画工具')
    //     if (list.length>0){
    //       return list[0].urls
    //     }else{
    //       return []
    //     }
    //   }else{
    //     return []
    //   }
    // }
  },
  data() {
    let reloadUrl;
    let LOCAL_URL = 'http://192.168.20.19:8082/#/video'
    let TEST_URL = 'http://39.99.242.100:1604/#/video'
    let PROD_URL = 'http://pccomm.xweiart.com/#/video'

    if (process.env.NODE_ENV === 'development') {
      reloadUrl = LOCAL_URL
    } else if (process.env.NODE_ENV === 'production') {
      if (process.env.VUE_APP_TITLE === 'test'){
        reloadUrl = TEST_URL
      }else if (process.env.VUE_APP_TITLE === 'build'){
        reloadUrl = PROD_URL
      }
    }

    return {
      play: false,
      first: true,
      activeNum: 0,
      reloadUrl,
    }
  },
  methods:{
    async hFirstPlay(s){
      this.first = false
      this.play = true
      this.$refs['video'].play()
      // const _t = await this.$_api('/online/app/disposeVideo/getVideoToken', 'get')
      // let newUrl = this.reloadUrl + `?i=${this.data.aesKey}&p=1&t=${_t}&n=${this.$route.query.tn}&b=${s}`
      //
      // window.WCRClassRoom.openUrlByExternal(newUrl)
      // window.open(newUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.before-class-card {
  margin: 30px;
  width: 393px;
  height: 267px;
  background: url("../assets/image/bg_prepare.png") no-repeat;
  background-size: 100% 100%;

  .before-class-card__title {
    padding-top: 15px;
    height: 31px;

    & > :nth-child(1) {
      position: relative;
      display: inline-block;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 18px;
      height: 24px;
      padding-left: 23px;
      color: inherit;

      & > :nth-child(1) {
        position: absolute;
        left: 0;
        top: 1px;
        width: 19px;
      }
    }
  }

  .before-class-card__content {
    margin: 20px;
    height: 181px;
    overflow-y: scroll;
    text-align: left;
    font-family: Microsoft YaHei;
    line-height: 30px;
    font-size: 14px;
    .img-preview + .img-preview {
      margin-top: 20px;
    }
    & .play{
      cursor: pointer;
      position: absolute;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
    }

    &.video {
      margin: 0;
      height: 246px;
    }

    div, span {
      color: inherit;
    }
  }
}
</style>
