<template>
  <div class="img-swipe">
    <img-preview :url="urls[activeNum]" v-if="urls.length > 0"></img-preview>
    <div class="pre-d" style="left: 0" @click="hPre"></div>
    <div class="next-d" style="right: 0" @click="hNext"></div>
  </div>
</template>

<script>
import ImgPreview from "@/components/ImgPreview";

export default {
  name: "imgSwipe",
  components: {ImgPreview},
  props: {
    urls: Array
  },
  data() {
    return {
      activeNum: 0,
    }
  },
  created() {
    console.log(this.urls)
  },
  methods:{
    hNext(){
      if (this.activeNum === this.urls.length - 1){
        this.activeNum = 0
      }else{
        this.activeNum++
      }
    },
    hPre(){
      if (this.activeNum === 0){
        this.activeNum = this.urls.length - 1
      }else{
        this.activeNum--
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.img-swipe {
  max-width: 100%;
  max-height: 100%;
  position: relative
}
</style>
