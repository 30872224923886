<template>
  <div class="before-class page">
<!-- 课前预习-->
    <route-back/>
    <live-title title="预习"/>
    <div class="before-class__container over-flow hidden-scroll">
      <before-class-card
          v-for="(i, k) in cardList"
          :key="k"
          :title="i.name"
          :color="i.color"
          :icon="i.icon"
          :t="i.key"
          :data="info"
          v-if="i.show"
      >
      </before-class-card>
    </div>
  </div>
</template>

<script>

import RouteBack from "@/components/RouteBack";
import LiveTitle from "@/components/LiveTitle";
import BeforeClassCard from "@/components/BeforeClassCard";

export default {
name: "BeforeClass",
  components: {BeforeClassCard, LiveTitle, RouteBack},
  data(){
    return {
      cardList: [
        {
          id: 1,
          name: '课程重点',
          color: '#FB631C',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAADBElEQVRIS63UW2gVRxwG8O+bnV0TtUlIvMRqiyDxgoYOeEWIWPBS+mIfGpNqkQFJhIioUAlUUV/ipViiLT5UVIy0eEOK9sESEYJSSBAxWCJt0KCCtwTjLTHN5cy/7MmeekzOiQl0Xpbdmfnt7Ow3fyJqXT99thfAFoAZiWcjvMZE5MJttpXO23Cjl+HkzuqiSSrIfDRCKOVwQlZmbLxcG4c7qpcaT6mb/wcMOJu5ua6mH/5usdHKfx98CeQjiKwfagEKYoNvrkZw1WKjPQ4F90E4o6019njcRNVCID8drggbVP4RwbvnG629tDCBX0btqP86xLqrFlWKYF9aGLDBjvoI/tYYP9DpYIFi4aid15tCrL1ybvaYTN4DkJMKJ8UGu24kwSraChEhcRBkIxSb33T3/p2z78/nyUhrxeyxOXm6QKBmAJgFJ1tBfhCOoYgNqhojeNts43v/paIjkM4p3N/ycjgpOVsMb9W0wkYAc+IwnQ32NvXDPVsKDLzkVEiD39O7gj/eeTUUHkc/mllDqrVvxzkbfP9XBFcUGPgDUyENvpO0eBydVFBDMgkFILDBD80RXDbVMEiZij3+4bvbU626r2JqidA7PbCPgNWH7ybgyYZqcCpEUBYcuX80FdxTPmUh6dUPgkWsPvIggu1kw1QHhLEl/tHH1xKTpRgezyEW3j9bm5uVlTF60A8mxOpjDyN4zXijUhyQzq43ednnXrXLmvwFfR4OgBhPJ5X656cXQ7xvXf59AB8PWLXVJ59EcHGOUf7gWsGY2ySKSwB+CSKeoLCJyDWKHIfiIYBZ78DirD71LAnmkLViOJFOvNbqsy8i+IsxxlPpa8UIVCgRy19fR/DnvvH0e8vmsHwFZ3nxnwhe7hvtpyn0Ii8FOK/AM/DcC+dUCQWlID5MWYScs/y9N4I/xSda6/C8J/apS4gLCjiDltgl3kF3MiKAwnJd5CClBFYDzE30h9WNtbF+WJYiwxEnCGSSON3m8NuEOnQM59tlLnxkY5kTfEVgOhXKeQW3/gX3WB8mBg+wpgAAAABJRU5ErkJggg==',
          key: 'emphasisImgList',
          show: false,
        },
        {
          id: 2,
          name: "动画预告",
          color: '#968DFA',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAVCAYAAABCIB6VAAACp0lEQVRIS62US0iUURzFz7lj5qSGqahIIURFi8AEWxhCqzbVoo1QUouCKFErtfE1qJ9lOSmWGYLag4iooDZB1KZoUZseYIsWEghFaSWNj6wcG50TMz7Q+GYcsLu63HvO73/vuQ9iQbMsxVoW/ywci7b/r5dBo2U9i0mJz3tCIl9SY6nLeSZa4IWyT84VmWlPAWxTQNXHq+Lagt4Q+FKLb6Ok9zMwTvmnmONyr3wXDby9ZaIeQuOs9+3JqricebAgXmie6AWQHRwU9ORUTfzOpcBNTb/XOR3sA7QqBCMbyqudp+fBwU7rWd9uYfrhHExAF0QfoERRTsLESiINfZImAUwacCugvFnPGP2BTS4rcWge3Nz065ik8wRWL7XKSPMCRyUVu+sTbvO0Nb7PEHeWA1zkJaYRcGxnY8OPt3PZ/i+4gPusqxvzA4gJD1Uvae4B2CUpP5riJPpY6x6dAuGwNQj+wDTSPZ6kEUB0u0eKQdMsICFSAQL9rKodHgdoKyTg9Zxbk7oQUl09uh5G1wXsiLDLPp6qGf4AKctORNDb6kleBJ7RiRVVXg/JSnu4ec3ySu9zIVx29F5sSbEBAy7Xz4wp+r7YgQU95vGK79dAHbatTHo7WlNtwSUVQycM2W7vQxdLKr6XSoGOMAJvZ1vaInBR0cc1iFt1yRAHgpGEybmER0sGcxnjeB1GMOkbTUu6cSP4tIEjJ77ucZDdADIj3gohm5YlMzD8bRBAun1eeAThlog9BtwfYZVz9v4rHRkbQls5VDrQSJn6aC7/khqy7PrljPYQ+ODBr/FmdeANgM1LGiMLXq30D+b39OT658MvLB7MMgE9WMa/8cIY7b3ZudYbrL3oVAsKFLsi+XORwEIBWwCEPvBwjcA4yJcSrt7tzrwHMDCn/Qv/c/zR8o0QlwAAAABJRU5ErkJggg==',
          show: false,
        },
        {
          id: 3,
          name: "作画工具",
          color: "#FCAB01",
          icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAADwklEQVRIS7WVT4hVVQDGf9+59703b/44T53KpD8gmkaQZovKAm0RQjRKiBK4yIJatDAhZpWEGGHgJkooiAikgrRIAhUNGQUz0igXgRkhEqFkmc3YzJs3797zxX1vlMbRwU1ndznnfOec798V/9PQzeB6C4HlBHoQZ4isJUp4ur3XBfZ3lC4zt7ejyp0hJIsduUeihgg2dcRvKH6fEk6TVC5qwS+Naw+ZBFwAUr3j7iz3KsRaSfcBJVCCLYrVBkm5cSY4H2P8JJJ/Vq72/PzfA64C+4daLafzSYlXLeaBAlAH10Ed4G5QOvlmNtI49gXMliSMfKH7hy4Va1rAPtrXk3WGTVIYwHQi/iToEMRPkzQeb9Y9N4R0u9AyQ8cUbuUIjMpxe0gb7xTgbeATfQtzwrdIM7CHRdgc8vGd1P8aZQU5h0maXbMfCCE5iFW7rmiFmKaeyAP0XvigDXxsxqw87dhPjEtBYyZfk9aTYxz5Y7Tthp5aMyutCkp2ANWpwC4ckhcMSPyexfhEG3gXSWNu3/xS4o8NizDDtnfL8VCOO0JIBiQWgHowBfftISL2kNEJ4bOWFsheYnjrqniDg6QrqrPmxMwbbV5AVCZuEcDlqcK1HJIj3k8ctwOXxiK1NAlfCs5NtpvR0NHeWre9Eod3Eb1XBL5BGCLmMjHuQT5ihQ1BWhLtc/I+KlQpUUIMo3/orlQqsQjGZqTVk54+Fb3g9bLhPNCQfCtRc4xPKfuqugbCc4WNBKlEt+E2oT7TomO62NeRNibNfO9IRa5mPGNpG2Kf8gOdP7YEawXCI0hDgmi3aOgCkuvQkIMzpCJAe5Jmvo2EmCu8DuqXeEX5/urbNi+1bibvTPCboEZuHgW9N2Gv4skBkWAy8K8K2hHt3oCenQiVwF2gn0KZ1fKB8qLY1G7DwoD3NBphoCMdazZV6k+SsNVmRPgj04r5U4UXnLMu7WwcZ4xSpsoGwRtFpwj/7aj1yVjjoAa3kD72YLpcUR8CM6FInjKL2YVHY1D/SNY8U0npKjl9UWgT5qTtw6iIt1YK7i0iTeDlZHZzt5ZRvxKQMpX04Sz3VsFDQHni6cOYvcJfRzMLsV5o/sRcjlu9nGOfcqLXkpAPqp/RqyXUSl9R5ou5Kzo8HaOfF8wrXNJqcxOLLm4J2a734rspOEvQzpDFz7mFs3q84P9KKK+R3Lsok9OXi6UWj7SjzO2GLsGIxLkYOa3AN0ngJDkXtY7xaYv+2smiQwpaLkF5ZhGgESJlmsC41rVK54bjpv550wHcaO5fV1OeQX6yHGcAAAAASUVORK5CYII=",
          key: 'paintingToolsImgList',
          show: false,
        },
        {
          id: 4,
          name: '创作示范',
          color: "#F84998",
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAADG0lEQVQ4T32Uf0xNYRjHv897SynNmspwrbVmGBoiE5E1kSNh5NfmYjNrzPgDf7Bp1ubnrGwY2vxDrRnrxrnJQvObNGt+/mEpSjWTIe69dc772CnOzrn3zPvXOc/7fT7P+z7f5xwCgOcFj48QYY3xHLoi+yNy0tSZLUa8ccWTAsFc6qSDxIH0mtmXydh8UfAok4kfOgmZ+US6d+6eQd2Du0yUHaZj+v1L091Zata3AaCxmgvuNzOQFiom8Nee77o7IV6k6FK8BmDmWLQXp3qzthjv5ubLZQ1FIJwZFJEEo1oSVfr79Ccj4P7S7m+nYdGcHBXpyiaBLQBn/AOSRMbk69mNNuCDZd64eMR1gLgVHOERQg6XUhYByAYhCQwGoQWMWkCUgXk6BJ8F8GGSN2eGCbde8a1S7xmqabcCUeIUg1Y5Nn8w2C+YS3SOqhQcnDhBza1xBHbl1sX+iObbDMwKgUkAwqHA6fE1i3dY47YGv1964zxIbA13EWUgzAMwLcw0nden+pTKsBO2LK4dj0jtjcNJ/IG+vtShrugMdslqh2If23qbUhc0FGs2U1qV6hIQ9lsqaUxoI4kLyeryo4b1rfneKmJkMniM7ZqMhcnq8nob8JNy7Q4ICwaEDFX79X1VSsPmgJMx7XlX3dKFewRK+as/OFZdecgG7FCuvABhqglgXH6osqcQhboV+jn/egJk4A4Tppi3YZSOVlfvtgE7laqnAMxhHazMe0f51h63AjuVKhXAEtvJGcdG+dbsswG7lYrzAGwOC8bWRN/6cmtyt1JxCcAGa0wwbUj0rauwAXvyLuVJYp+t2dKVKag/IEElDGqTpB0SiNwE8OF/Ogb8waAc677t+WoDFqNY7MxLeQ6yzJrkmyBaBDK/eT8Yz0CYbwIZZSNqPbvC5tAI/Mwtn6xHiEcA4pzcdYi96vvtn53UsL3XEWgEfywsn4MIGAOc8D8oAU1C6vmxdds6bW1ySurNKR1JQ2KKAd4IICbE0S4CTnZQ8NS42p3B0Hynn6Wp+Zx+LiY+iWZCymSANBfwrrquvrkQV2yzaYX+ARCALCTfoAz+AAAAAElFTkSuQmCC',
          key: 'demonstrationImgList',
          show: false
        },
      ],
      info: {}
    }
  },
  created() {
    this.gInfo()
  },
  methods:{
    async gInfo(){
      this.$pageLoadingOpen()
      const _res = await this.$_api('/online/app/course/center/preview', 'post', {
        topicId: this.$route.query.topicId
      })
      this.info = _res.data
      if (_res.data.emphasisImgList && _res.data.emphasisImgList.length > 0){
        this.cardList[0].show = true
      }
      if (_res.data.paintingToolsImgList && _res.data.paintingToolsImgList.length>0){
        this.cardList[2].show = true
      }
      if (_res.data.demonstrationImgList && _res.data.demonstrationImgList.length>0){
        this.cardList[3].show = true
      }

      let _ = _res.data.resourcesList.filter(v=>v.typeName === '动画预告')
      if (_.length === 1 && _[0].urls.length>0){
        this.cardList[1].show = true
      }


      this.$pageLoadingClose()
    }
  }
}
</script>

<style lang="scss" scoped>
  .before-class{
    position: relative;
    background: url("../assets/image/bg-zb.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: hidden;
    .route-back{
      top: 30px;
      left: 30px;
    }
    .live-title{
      top: 30px;
    }
    .before-class__container{
      height: 500px;
      overflow-y: scroll;
      margin: 126px 76px 30px;
    }
  }
</style>
