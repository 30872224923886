<template>
  <div class="img-preview" @click="hFullShow" :class="{'full-screen': isPreview}" :style="`background: url(${url}) 0% 0% / 100% 100% no-repeat;`">
    <!--    图片组件-->
<!--    <img :src="url" alt="">-->

    <div class="img-preview__fullscreen" v-if="!isPreview"></div>
    <div class="img-preview__fullscreen" v-if="isPreview"></div>
  </div>
</template>

<script>
export default {
  name: "ImgPreview",
  props:{
    url: String,
  },
  data(){
    return {
      isPreview: false,
    }
  },
  methods:{
    hFullShow(){
      this.isPreview = !this.isPreview
    }
  }
}
</script>

<style lang="scss" scoped>
  .img-preview{
    max-width: 100%;
    height: 181px;
    position: relative;
    transition: all .4s;
    &.full-screen{
      position: fixed;
      width: 100%;
      left: 0;
      height: 100%;
      top: 0;
      background-color: rgba(0, 0, 0, .7) !important;
      background-size: 70% auto!important;
      background-position: 50% 50% !important;
      z-index: 1000!important;
    }
    .img-preview__fullscreen{
      position: absolute;
      width: 22px;
      height: 22px;
      background: url("../assets/image/ic_zoom.png") no-repeat;
      background-size: 100% 100%;
      bottom: 10px;
      right: 5px;
      cursor: pointer;
    }
  }
</style>
